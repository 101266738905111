/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, CardHeader, Button, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { FaUnity, FaSearch, FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const HospitalPaymentReport = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_hospital_payments_handler();
  }, []);

  const get_hospital_payments_handler = () => {
    setLoading(true);
    setTableData('');

    axios.post(mainurl + 'Admin/payment_list')
      .then(function (response) {
        console.log(response.data);
        const list = response.data.map((row, index) => {
          let status = row.status === '0' ? 'Unpaid' : 'Paid';
          let moa;
          const modeOfAdmission = Number(row.mode_of_admission);

          if (modeOfAdmission === 1) {
            moa = "Swasthya Sathi";
          } else if (modeOfAdmission === 2) {
            moa = "Insurance Policy";
          } else {
            moa = "Cash";
          }

          const formatDate = (date) => {
            if (!date || date === "0000-00-00") {
              return "-";
            }
            const parsedDate = new Date(date);
            return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
          };

          return (
            {
              sl: index + 1,
              ModeofAdmission: moa,
              IdNumber: row.policy_number,
              PatientName: row.patient_name,
              DischargeDate: formatDate(row.discharge_date),
              HospitalName: row.hospital_name,
              ApprovedAmount: '₹' + row.hospital_approval_amount,
              Commission: '₹' + row.hospital_commission,
              ChannelName: row.channel_name,
            }
          )
        })
        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const filter_data_handler = (e) => {
    let filterData = tableData.filter(item => {
      const query = e.target.value.toLowerCase();
      console.log(query);
      return (
        item?.ChannelName?.toLowerCase().indexOf(query) >= 0 ||
        item?.ModeofAdmission?.toLowerCase().indexOf(query) >= 0 ||
        item?.PatientName?.toLowerCase().indexOf(query) >= 0 ||
        item?.HospitalName?.toLowerCase().indexOf(query) >= 0 ||
        item?.PaymentCycle?.toLowerCase().indexOf(query) >= 0
      )
    })
    setFilterData(filterData);
  }

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filterData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hospital Payment Report");
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(data, "Hospital_Payment_Report.xlsx");
  }

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={6} className='mb-2'>
            <Title pageTitle="Hospital Payment Report" />
          </Col>
          <Col xs={6} className="d-flex justify-content-end align-items-center mb-2">
            <Button className="text-white me-2" color='success' onClick={exportToExcel}>
              <FaFileExcel /> Export to Excel
            </Button>
            <InputGroup size="sm" className="me-2" style={{ maxWidth: '400px' }}>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>

        {log_data.roleId === 1 && (
          <>
            <Card color="primary" outline className='mb-3'>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <DataTable
                      className='mb-1'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Admission Type',
                          selector: row => row.ModeofAdmission,
                        },
                        {
                          name: 'ID Number',
                          selector: row => row.IdNumber,
                          wrap: true
                        },
                        {
                          name: 'Patient Name',
                          selector: row => row.PatientName,
                          wrap: true,
                          width: '200px'
                        },
                        {
                          name: 'Discharge Date',
                          selector: row => row.DischargeDate,
                          wrap: true,
                          width: '200px'
                        },
                        {
                          name: 'Hospital Name',
                          selector: row => row.HospitalName,
                          wrap: true
                        },
                        {
                          name: 'Approved Amount',
                          selector: row => row.ApprovedAmount,
                          wrap: true
                        },
                        {
                          name: 'Commission',
                          selector: row => row.Commission,
                          wrap: true
                        },
                        {
                          name: 'Channel Name',
                          selector: row => row.ChannelName,
                          wrap: true
                        },
                        // {
                        //   name: 'Status',
                        //   selector: row => row.Status,
                        //   wrap: true
                        // },
                      ]}
                      data={filterData}
                      highlightOnHover
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      progressPending={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>)}
      </Page>
      <Footer />
    </div>
  )
}

export default HospitalPaymentReport;