import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../component/hocs/notification';
import { useDispatch } from 'react-redux';
import { signIn } from './../component/redux/logged';
import Footer from './../component/layout/Footer';
import axios from 'axios';
import { mainurl } from '../MainUrl';
import { Col, Row, Input, Button } from 'reactstrap';
import logo from './../assets/img/logo.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const login_handler = () => {
    setLoading(true);

    const hardcodedAdminUserName = 'Admin';
    const hardcodedAdminPassword = 'Admin123';
    const hardcodedUserUserName = 'User';
    const hardcodedUserPassword = 'User123';

    if (userName === hardcodedAdminUserName && password === hardcodedAdminPassword) {
      const user_id = 1;
      const role_id = 1;
      const message = 'Admin login successful!';

      sessionStorage.setItem('aktc_user_id', user_id);
      sessionStorage.setItem('aktc_role_id', role_id);
      console.log("Admin logData:", {
        login: true,
        userName: hardcodedAdminUserName,
        userId: user_id,
        roleId: role_id,
        logData: { message }
      });

      dispatch(signIn({
        login: true,
        userName: hardcodedAdminUserName,
        userId: user_id,
        roleId: role_id,
        logData: { message }
      }));

      let notify = notification({ message, type: 'success' });
      notify();
      navigate('/home');
      setLoading(false);
      return;
    }

    if (userName === hardcodedUserUserName && password === hardcodedUserPassword) {
      const user_id = 2;
      const role_id = 2;
      const message = 'User login successful!';

      sessionStorage.setItem('aktc_user_id', user_id);
      sessionStorage.setItem('aktc_role_id', role_id);
      dispatch(signIn({
        login: true,
        userName: hardcodedUserUserName,
        userId: user_id,
        roleId: role_id,
        logData: { message }
      }));

      let notify = notification({ message, type: 'success' });
      notify();
      navigate('/home');
      setLoading(false);
      return;
    }

    let postData = {
      "user_name": userName,
      "password": password,
    };

    axios({
      method: 'post',
      url: mainurl + 'Login/user_login',
      data: postData,
      headers: { 'Content-Type': 'application/JSON' }
    })
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          const { user_id, user_name, role_id, message } = response.data;
          sessionStorage.setItem('aktc_user_id', user_id);
          sessionStorage.setItem('aktc_role_id', role_id);
          dispatch(signIn({
            login: true,
            userName: user_name,
            userId: user_id,
            roleId: role_id,
            logData: response.data
          }));
          let notify = notification({ message: response.data.message, type: 'success' });
          notify();
          navigate('/home');
        } else {
          let notify = notification({ message: response.data.message, type: 'error' });
          notify();
        }
      })
      .catch(error => {
        let notify = notification({ message: "Invalid Credentials! Try Again", type: 'error' });
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center br-5 loginPage">
        <Col md={3}>
          <div className="card p-4 shadow-lg" style={{ borderRadius: '15px', backgroundColor: 'white' }}>
            <Row className="d-flex justify-content-center">
              <Col md={8} className="mb-4">
                <div className="text-center mb-3">
                  <img height="120px" src={logo} alt="logo" />
                </div>
              </Col>
              <Col md={10} className='mb-3'>
                <Input
                  type="text"
                  bsSize="lg"
                  placeholder='Email'
                  style={{ borderRadius: '5px' }}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
              </Col>
              <Col md={10} className='mb-3'>
                <Input
                  type="password"
                  bsSize="lg"
                  placeholder='Password'
                  style={{ borderRadius: '5px' }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Col>
              <Col md={10} className='mb-3'>
                <Button
                  className='text-white'
                  color='primary'
                  style={{
                    borderRadius: '0.5rem',
                    fontWeight: '700',
                    minWidth: '64px',
                    boxShadow: '0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2)'
                  }}
                  block
                  onClick={login_handler}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? 'Signing in...' : 'Sign in'}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Footer /> */}
    </>
  );
}

export default Login;
