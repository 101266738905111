import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';
import axios from 'axios';
import { mainurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import FileBase64 from 'react-file-base64';

import {
  Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Label, Form, Spinner, Input, CardHeader,
} from 'reactstrap';

import { FaUnity } from 'react-icons/fa';

import { BsPencilSquare } from 'react-icons/bs';

const PatientView = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [hospitalData, setHospitalData] = useState([]);

  const [kycData, setKycData] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [moreHospitalModal, setMoreHospitalModal] = useState(false);
  const [moreHospitalEditModal, setMoreHospitalEditModal] = useState(false);

  const [kycDocument, setKycDocument] = useState(null);
  const [typeAd, setTypeAd] = useState(null);

  const [moreHospital, setMoreHospital] = useState(null);
  const [admitDate, setAdmitDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const [hpcId, setHpcId] = useState(null);

  const [moreChannel, setMoreChannel] = useState(null);

  const [moreHospitals, setMoreHospitals] = useState([]);

  const [kycImages, setKycImages] = useState([]);
  const [kycExtension, setKycExtension] = useState([]);

  const [kycBackImages, setKycBackImages] = useState([]);
  const [kycBackExtension, setKycBackExtension] = useState([]);

  const [kycName, setKycName] = useState([]);

  const [chChannel, setChChannel] = useState([]);
  const [chAmount, setChAmount] = useState([]);

  const [edHospitalId, setEdHospitalId] = useState('');
  const [edAdmitDate, setEdAdmitDate] = useState('');
  const [edDischargeDate, setEdDischargeDate] = useState('');
  const [edIcuDays, setEdIcuDays] = useState('');
  const [edGenDays, setEdGenDays] = useState('');
  const [edMedication, setEdMedication] = useState('');
  const [edApproval, setEdApproval] = useState('');
  const [edCommission, setEdCommission] = useState('');

  const [kycStatus, setKycStatus] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [channelPaymentId, setChannelPaymentId] = useState('');

  const [edModalOpen, setEdModalOpen] = useState(false);
  const [edNewStatus, setEdNewStatus] = useState('');
  const [edPaymentId, setEdPaymentId] = useState('');

  const { patient_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllChannels();
    getPatientData();
    getHospitalData();
    getChannelData();
    getMoreHospitals();
    getKycData();
  }, []);

  const getPatientData = async () => {
    try {
      const response = await axios.post(mainurl + 'Admin/patient_view', { patient_id });
      setPatientData(response?.data[0] ?? '');
    } catch (error) {
      console.log(error);
      notification({ message: 'Failed to fetch patient data!', type: 'error' })();
    }
  };

  const getAllChannels = async () => {
    try {
      const response = await axios.post(mainurl + 'Admin/channel_list');
      const data = response?.data;

      setChChannel(Array.isArray(data) ? data : []);
    } catch (error) {
      console.log("Error fetching channels:", error);
      setChChannel([]);
    }
  };


  const getMoreHospitals = async () => {
    try {
      const response = await axios.post(mainurl + 'Admin/hospital_master_list');
      setMoreHospitals(response?.data ?? '');
    } catch (error) {
      console.log("Error fetching hospitals:", error);
    }
  };

  const shareholder_image_handler_kyc = (files) => {
    console.log(files);

    let imgtype = (files.type).split('/')[1];
    setKycImages(files.base64);
    setKycExtension(imgtype);
  }

  const shareholder_image_handler_kyc_back = (files) => {
    console.log(files);

    let imgtype = (files.type).split('/')[1];
    setKycBackImages(files.base64);
    setKycBackExtension(imgtype);
  }

  const hospital_add_handler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {

      const setNullIfEmpty = (value, isStringForNull = false) => {
        if (Array.isArray(value) && value.length === 0) {
          return isStringForNull ? "" : null;
        }
        if (value === null || value === undefined || value === "") {
          return isStringForNull ? "" : null;
        }
        return value;
      };

      let postData = {
        "hospital_header_id": setNullIfEmpty(moreHospital),
        "patient_id": setNullIfEmpty(patient_id),
        "channel_amount": setNullIfEmpty(chAmount),
        "channal_amount_status": 0,
        "channal_header_id": setNullIfEmpty(moreChannel.channel_header_id),
        "admit_date": setNullIfEmpty(admitDate),
        "patient_status": 0,
        "mode_of_admission": setNullIfEmpty(kycDocument),
        "type_of_admission": setNullIfEmpty(typeAd),
        "policy_number": setNullIfEmpty(kycName),
        "policy_front_image": setNullIfEmpty(kycImages, true),
        "policy_front_image_extension": setNullIfEmpty(kycExtension, true),
        "policy_back_image": setNullIfEmpty(kycBackImages, true),
        "policy_back_image_extension": setNullIfEmpty(kycBackExtension, true),
        "channel_due_date": setNullIfEmpty(dueDate)
      };

      console.log(postData);

      const response = await axios.post(mainurl + 'Admin/hpc_maping', postData, {
        headers: { 'Content-Type': 'application/JSON' },
      });

      // setLoading(false);
      notification({ message: "Patient admitted successfully", type: 'success' })();
      setMoreHospitalModal(false);
      getHospitalData('');
      setMoreHospital('');
      setKycImages('');
      setKycExtension('');
      setKycBackImages('');
      setKycBackExtension('');
      // setChChannel('');
      setChAmount('');
      setTypeAd('');
      setKycDocument('');
      setMoreChannel('');
      getChannelData();
      getHospitalData();
      getKycData();

    } catch (error) {
      // setLoading(false);
      console.error(error);
      notification({ message: "Failed! Try Again 1", type: 'error' })();
    } finally {
      setLoading(false);
    }
  }

  const getHospitalData = async () => {
    // const response = await axios.post(mainurl + 'Admin/patient_wish_hospital', { patient_id });
    axios.post(mainurl + 'Admin/patient_wish_hospital', { patient_id })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          let moa;
          const modeOfAdmission = Number(row?.mode_of_admission ?? '0');

          if (modeOfAdmission === 1) {
            moa = "Swasthya Sathi";
          } else if (modeOfAdmission === 2) {
            moa = "Insurance Policy";
          } else {
            moa = "Cash";
          }

          const formatDate = (date) => {
            if (!date || date === "0000-00-00") {
              return "-";
            }
            const parsedDate = new Date(date);
            return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
          };

          return (
            {
              sl: index + 1,
              HospitalName: row?.hospital_name ?? '',
              AdmitDate: formatDate(row.admit_date),
              DischargeDate: formatDate(row.discharge_date),
              IcuDays: row?.no_days_icu ?? '',
              GenDays: row?.no_days_gen ?? '',
              OthDays: row?.no_days_others ?? '',
              ModeofAdmission: moa,
              ApprovalAmount: '₹' + row?.hospital_approval_amount ?? '',
              Commission: '₹' + row?.hospital_commission ?? '',
              Action: <>
                <BsPencilSquare className='cursor' color='#fe9300' size={22} onClick={() => open_edit_handler(row)} />
              </>
            }
          )
        })
        setHospitalData(list);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const open_edit_handler = (row) => {
    const { hpc_map_id, name, admit_date, discharge_date, no_days_icu, no_days_gen, no_days_others } = row;

    setEdHospitalId(name);
    setEdAdmitDate(admit_date);
    setEdDischargeDate(discharge_date);
    setEdIcuDays(no_days_icu || 0);
    setEdGenDays(no_days_gen || 0);
    setEdMedication(no_days_others || 0);
    setEdApproval(row.hospital_approval_amount);
    setEdCommission(row.hospital_commission);
    setHpcId(hpc_map_id);
    setMoreHospitalEditModal(true);
  }

  const hospital_edit_handler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {

      const setNullIfEmpty = (value) => (value === '' || value === undefined || value === null ? null : value);

      let postData = {
        "hpc_map_id": hpcId,
        "discharge_date": setNullIfEmpty(edDischargeDate),
        "no_days_icu": setNullIfEmpty(edIcuDays),
        "no_days_gen": setNullIfEmpty(edGenDays),
        "no_days_others": setNullIfEmpty(edMedication), // medication cost
        "hospital_approval_amount": setNullIfEmpty(edApproval), // approval amount
        "hospital_commission": setNullIfEmpty(edCommission), // approval amount
      };

      console.log(postData);

      const response = await axios({
        method: 'post',
        url: mainurl + 'Admin/hpc_maping_edit',
        data: postData,
        headers: { 'Content-Type': 'application/JSON' }
      });
      setLoading(false);
      setMoreHospitalEditModal(false);
      let notify = notification({ message: "Details Updated Successfully", type: 'success' });
      notify();

      getHospitalData('');
      setEdHospitalId('');
      setEdAdmitDate('');
      setEdDischargeDate('');
      setEdIcuDays('');
      setEdGenDays('');
      setEdMedication('');
      setEdApproval('');

    } catch (error) {
      setLoading(false);
      console.error(error);
      notification({ message: "Failed! Try Again 2", type: 'error' })();
    }
  }

  const getKycData = async () => {
    try {
      const response = await axios.post(mainurl + 'Admin/patient_wish_kyc', { patient_id });
      const transformedData = transformKycData(response?.data ?? '');
      setKycData(transformedData);

      if (transformedData.length > 0) {
        const firstKyc = transformedData[0];
        setKycStatus(firstKyc.status);
      }
    } catch (error) {
      console.log(error);
      // notification({ message: 'Failed to fetch kyc data!', type: 'error' })();
    }
  };

  const transformKycData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((row, index) => {
      let status;
      const modeOfAdmission = Number(row?.mode_of_admission ?? '0');

      if (modeOfAdmission === 1) {
        status = "Swasthya Sathi";
      } else if (modeOfAdmission === 2) {
        status = "Insurance Policy";
      } else {
        status = "Cash";
      }

      return {
        sl: index + 1,
        status: status,
        PolicyNumber: row?.policy_number ?? '',
        KycFrontImage: row?.policy_front_image ?? '',
        KycBackImage: row?.policy_back_image ?? '',
      };
    });
  };

  const getPatientUrn = () => {
    if (kycStatus === "Swasthya Sathi") {
      return `S-${patientData.patient_id}`;
    } else if (kycStatus === "Cash") {
      return `C-${patientData.patient_id}`;
    } else {
      return patientData.patient_id;
    }
  };

  const getChannelData = async () => {
    try {
      const response = await axios.post(mainurl + 'Admin/patient_wish_channel', { patient_id });
      const data = response.data;

      if (!Array.isArray(data)) {
        console.error("Expected an array but received:", data);
        setChannelData([]);
        setLoading(false);
        return;
      }

      const list = data.map((row, index) => {
        const status = row?.channal_amount_status === '0' ? 'Unpaid' : 'Paid';

        const formatDate = (date) => {
          if (!date || date === "0000-00-00") {
            return "-";
          }
          const parsedDate = new Date(date);
          return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
        };

        return {
          sl: index + 1,
          DueDate: formatDate(row.channel_due_date),
          ChannelName: row?.name ?? '',
          ChannelContact: '+91 ' + (row?.phone_no ?? ''),
          ApprovalAmount: '₹' + (row?.channel_amount ?? ''),
          PaymentCycle: (row?.channel_payment_cy ?? '') + ' Days',
          Status: status,
          Action: row?.channal_amount_status === '0' ? (
            <button
              className="text-white me-2"
              style={{
                backgroundColor: '#fe9300',
                color: '#fff',
                border: 'none',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
              onClick={() => openEditModal(row)}
            >
              Pay Now
            </button>
          ) : (
            <button
              style={{
                backgroundColor: '#28a745',
                color: '#fff',
                border: 'none',
                padding: '5px 10px',
              }}
              disabled
            >
              Paid
            </button>
          ),
        };
      });

      setChannelData(list);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const openEditModal = (row) => {
    setChannelPaymentId(row.hpc_map_id);
    setNewStatus(row.channal_amount_status);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleStatusUpdate = async () => {
    setLoading(true);
    let postData = {
      "hpc_map_id": channelPaymentId ?? 0,
      "channal_amount_status": newStatus
    };
    axios({
      method: 'POST',
      url: mainurl + 'Admin/channel_payment_status_update',
      data: postData,
      headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {

      setLoading(false);
      closeModal();
      let notify = notification({ message: "Status updated successfully", type: 'success' });
      notify();
      setChannelPaymentId('');
      setNewStatus('');
      getChannelData();

    }).catch(error => {
      setLoading(false);
      console.log(error);
      let notify = notification({ message: "Failed! Try Again 3", type: 'error' });
      notify();
    })
  };

  const edOpenEditModal = (row) => {
    setEdPaymentId(row.payment_id);
    setEdNewStatus(row.status);
    setEdModalOpen(true);
  };

  const edCloseModal = () => {
    setEdModalOpen(false);
  };

  const handleEdStatusUpdate = async () => {
    setLoading(true);
    let postData = {
      "payment_id": edPaymentId ?? 0,
      "status": edNewStatus
    };
    axios({
      method: 'POST',
      url: mainurl + 'Admin/hospital_payment_status_update',
      data: postData,
      headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {

      setLoading(false);
      edCloseModal();
      let notify = notification({ message: "Status updated successfully", type: 'success' });
      notify();
      setEdPaymentId('');
      setEdNewStatus('');

    }).catch(error => {
      setLoading(false);
      console.log(error);
      let notify = notification({ message: "Failed! Try Again 4", type: 'error' });
      notify();
    })
  };

  const formatDate = (date) => {
    if (!date || date === "0000-00-00") {
      return "-";
    }
    const parsedDate = new Date(date);
    return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
  };

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={9} className="mb-3">
            <Title pageTitle="Patient Details View" />
          </Col>
          <Col xs={3} className="d-flex justify-content-end align-items-center mb-3">
            <Button className='text-white me-2' color='primary' onClick={() => setMoreHospitalModal(true)} > <FaUnity size={20} />  Admit Patient </Button>
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className="mb-3">
              <CardHeader>Patient Information</CardHeader>
              <CardBody>
                <Row>
                  {patientData ? (
                    <>
                      <Label sm={3}><b>Patient ID: </b></Label>
                      <Label sm={3}>{getPatientUrn()}</Label>

                      <Label sm={3}><b>Name:</b></Label>
                      <Label sm={3}>{patientData.patient_name}</Label>

                      <Label sm={3}><b>Contact: </b></Label>
                      <Label sm={3}>{patientData.phone_no}</Label>

                      <Label sm={3}><b>Email: </b></Label>
                      <Label sm={3}>{patientData.email_id}</Label>

                      <Label sm={3}><b>Gender:</b></Label>
                      <Label sm={3}>{patientData.gender}</Label>

                      <Label sm={3}><b>Address: </b></Label>
                      <Label sm={3}>{patientData.address}</Label>

                      <Label sm={3}><b>Date of Birth: </b></Label>
                      <Label sm={3}>{formatDate(patientData.dob)}</Label>

                      <Label sm={3}><b>Status: </b></Label>
                      <Label sm={3}>{patientData.status === '0' ? 'Inactive' : 'Active'}</Label>
                    </>
                  ) : (
                    <Label sm={12}>Loading patient information...</Label>
                  )}
                </Row>
              </CardBody>
            </Card>

            <Card color="primary" outline className="mb-3">
              <CardHeader>Patient KYC Information</CardHeader>
              <CardBody>
                <DataTable
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Mode of Admission',
                      selector: row => row.status,
                      wrap: true
                    },
                    {
                      name: 'Policy Number',
                      selector: row => row.PolicyNumber,
                      wrap: true
                    },
                    {
                      name: 'KYC Front Image',
                      selector: row => row.KycFrontImage,
                      wrap: true
                    },
                    {
                      name: 'KYC Back Image',
                      selector: row => row.KycBackImage,
                      wrap: true
                    },
                  ]}
                  data={kycData}
                  pagination
                />
              </CardBody>
            </Card>

            <Card color="primary" outline className="mb-3">
              <CardHeader>Hospital Information</CardHeader>
              <CardBody>
                <DataTable
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Hospital Name',
                      selector: row => row.HospitalName,
                      wrap: true
                    },
                    {
                      name: 'Mode of Admission',
                      selector: row => row.ModeofAdmission,
                      wrap: true
                    },
                    {
                      name: 'Admit Date',
                      selector: row => row.AdmitDate,
                      wrap: true
                    },
                    {
                      name: 'Discharge Date',
                      selector: row => row.DischargeDate,
                      wrap: true,
                      width: '200px'
                    },
                    {
                      name: 'ICU Days',
                      selector: row => row.IcuDays,
                      wrap: true
                    },
                    {
                      name: 'Gen Days',
                      selector: row => row.GenDays,
                      wrap: true
                    },
                    {
                      name: 'Medicine / Investigation',
                      selector: row => row.OthDays,
                      wrap: true
                    },
                    {
                      name: 'Approval Amount',
                      selector: row => row.ApprovalAmount,
                      wrap: true
                    },
                    {
                      name: 'Commission',
                      selector: row => row.Commission,
                      wrap: true
                    },
                    {
                      name: 'Discharge Now',
                      selector: row => row.Action,
                    },
                  ]}
                  data={hospitalData}
                  pagination
                />
              </CardBody>
            </Card>

            <Card color="primary" outline className="mb-3">
              <CardHeader>Channel Information</CardHeader>
              <CardBody>
                <DataTable
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Payout Due Date',
                      selector: row => row.DueDate,
                    },
                    {
                      name: 'Channel Name',
                      selector: row => row.ChannelName,
                    },
                    {
                      name: 'Channel Contact',
                      selector: row => row.ChannelContact,
                    },
                    {
                      name: 'Referral Amount',
                      selector: row => row.ApprovalAmount,
                      wrap: true
                    },
                    {
                      name: 'Payment Cycle',
                      selector: row => row.PaymentCycle,
                      wrap: true
                    },
                    // {
                    //   name: 'Status',
                    //   selector: row => row.Status,
                    //   wrap: true,
                    //   cell: row => (
                    //     <span style={{ color: row.Status === 'Unpaid' ? 'red' : 'green' }}>
                    //       {row.Status}
                    //     </span>
                    //   )
                    // },
                    {
                      name: 'Action',
                      selector: row => row.Action,
                    },
                  ]}
                  data={channelData}
                  pagination
                />
              </CardBody>
            </Card>
            <Modal
              centered
              scrollable
              size="lg"
              isOpen={moreHospitalModal}
              toggle={() => setMoreHospitalModal(!moreHospitalModal)}
            >
              <ModalHeader toggle={() => setMoreHospitalModal(!moreHospitalModal)}>
                Admit Patient
              </ModalHeader>
              <ModalBody className=''>
                <Form onSubmit={hospital_add_handler}>
                  <Row>
                    <Col xs={6} className='mb-2'>
                      <Label>Hospital Name*</Label>
                      <Input id="moreHospital" name="moreHospital" placeholder="Hospital" type="select" value={moreHospital} style={{ fontSize: '14px' }} onChange={(e) => setMoreHospital(e.target.value)} required >
                        <option value=""> --Select Hospital-- </option>
                        {moreHospitals.map((ch) => (<option key={ch.hospital_id} value={ch.hospital_id}>{ch.name}</option>))}
                      </Input>
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Admit Date*</Label>
                      <Input
                        id="admit_date"
                        type="date"
                        value={admitDate}
                        onChange={(e) => {
                          const newAdmitDate = e.target.value;
                          setAdmitDate(newAdmitDate);

                          const paymentCycle = moreChannel?.channel_payment_cy ? parseInt(moreChannel.channel_payment_cy) : 0;

                          const admitDateObj = new Date(newAdmitDate);
                          admitDateObj.setDate(admitDateObj.getDate() + paymentCycle);
                          const calculatedDueDate = admitDateObj.toISOString().split('T')[0];
                          setDueDate(calculatedDueDate);
                        }}
                        required
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Channel Name*</Label>
                      <Input
                        id="channel"
                        name="channel"
                        placeholder="Channel"
                        type="select"
                        value={moreChannel?.channel_header_id ?? 0}
                        style={{ fontSize: '14px' }}
                        onChange={(e) => {
                          const selectedChannel = chChannel.find(cha => cha.channel_header_id === e.target.value);
                          setMoreChannel(selectedChannel || {});
                          if (selectedChannel) {
                            setChAmount(selectedChannel.commission);
                            if (admitDate) {
                              const paymentCycle = parseInt(selectedChannel.channel_payment_cy) || 0;
                              const admitDateObj = new Date(admitDate);
                              admitDateObj.setDate(admitDateObj.getDate() + paymentCycle);
                              const calculatedDueDate = admitDateObj.toISOString().split('T')[0];
                              setDueDate(calculatedDueDate);
                            }
                          }
                        }}
                        required >
                        <option value=""> --Select Channel-- </option>
                        {chChannel.map((ch) => (<option key={ch.channel_header_id} value={ch.channel_header_id}>{ch.name}</option>))}
                      </Input>
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Channel Referral Amount*</Label>
                      <Input
                        id="ref_amount"
                        placeholder="Enter Referral Amount"
                        type="number"
                        value={chAmount}
                        onChange={(e) => setChAmount(e.target.value)}
                        required
                      />
                    </Col>

                    <Col xs={12} className='mb-2'>
                      <Label>Mode of Admission*</Label>
                      <Input id="document" name="document" placeholder="Document" type="select" value={kycDocument} style={{ fontSize: '14px' }} onChange={(e) => setKycDocument(e.target.value)} required >
                        <option value=""> --Select MOA-- </option>
                        <option value="1">Swasthya Sathi</option>
                        <option value="2">Insurance Policy</option>
                        <option value="3">Cash</option>
                      </Input>
                    </Col>
                    {kycDocument === "1" && (
                      <>
                        <Col xs={12} className='mb-2'>
                          <Label>Type of Admission*</Label>
                          <Input id="typead" name="typead" placeholder="Type of Admission" type="select" value={typeAd} style={{ fontSize: '14px' }} onChange={(e) => setTypeAd(e.target.value)} required >
                            <option value=""> --Select TOA-- </option>
                            <option value="1">Conservative</option>
                            <option value="2">Surgery Case (above 50k)</option>
                            <option value="3">Neuro Cardio (above 90k)</option>
                          </Input>
                        </Col>
                        <Col xs={12} className="mb-2">
                          <Label for="kyc_name">URN*</Label>
                          <Input
                            id="kyc_name"
                            placeholder="Enter URN"
                            type="text"
                            value={kycName}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 17) {
                                setKycName(value);
                              }
                            }}
                            maxLength={17}
                            required
                          />
                          {kycName.length > 0 && kycName.length !== 17 && (
                            <small className="text-danger">URN must be exactly 17 characters</small>
                          )}
                        </Col>
                        <Col xs={6} className="mb-2">
                          <Label>Upload KYC Image (Front)</Label><br />
                          <FileBase64
                            multiple={false}
                            onDone={shareholder_image_handler_kyc}
                          />
                        </Col>
                        <Col xs={6} className="mb-2">
                          <Label>Upload KYC Image (Back)</Label><br />
                          <FileBase64
                            multiple={false}
                            onDone={shareholder_image_handler_kyc_back}
                          />
                        </Col>
                      </>
                    )}

                    {kycDocument === "2" && (
                      <>
                        <Col xs={12} className="mb-2">
                          <Label for="kyc_name">Policy Number*</Label>
                          <Input
                            id="kyc_name"
                            placeholder="Enter Policy Number"
                            type="text"
                            value={kycName}
                            onChange={(e) => setKycName(e.target.value)}
                            required
                          />
                        </Col>
                        <Col xs={12} className="mb-2">
                          <Label>Upload Policy Document</Label><br />
                          <FileBase64
                            multiple={false}
                            onDone={shareholder_image_handler_kyc}
                          />
                        </Col>
                      </>
                    )}

                    <Col xs={12} className='mb-2 text-end'>
                      <Button color='primary' disabled={loading}>
                        Admit Patient {loading && <Spinner size="sm" />}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>

            </Modal>

            <Modal
              centered
              scrollable
              size="lg"
              isOpen={moreHospitalEditModal}
              toggle={() => setMoreHospitalEditModal(!moreHospitalEditModal)}
            >
              <ModalHeader toggle={() => setMoreHospitalEditModal(!moreHospitalEditModal)}>
                Edit Patient
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={hospital_edit_handler}>
                  <Row>
                    <Col xs={6} className='mb-2'>
                      <Label>Hospital Name*</Label>
                      <Input
                        id="moreHospital"
                        name="moreHospital"
                        placeholder="Hospital"
                        type="text"
                        value={edHospitalId}
                        style={{ fontSize: '14px' }}
                        readOnly
                      >
                      </Input>
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Admit Date*</Label>
                      <Input
                        id="admit_date"
                        type="date"
                        value={edAdmitDate}
                        readOnly
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Discharge Date</Label>
                      <Input
                        id="discharge_date"
                        type="date"
                        value={edDischargeDate}
                        onChange={(e) => setEdDischargeDate(e.target.value)}
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>ICU Days</Label>
                      <Input
                        id="icu_days"
                        placeholder="Enter ICU Days"
                        type="number"
                        value={edIcuDays}
                        onChange={(e) => setEdIcuDays(e.target.value)}
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Gen Days</Label>
                      <Input
                        id="gen_days"
                        placeholder="Enter Gen Days"
                        type="number"
                        value={edGenDays}
                        onChange={(e) => setEdGenDays(e.target.value)}
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Medicine / Investigation</Label>
                      <Input
                        id="other_days"
                        placeholder="Enter Medication Cost"
                        type="number"
                        value={edMedication}
                        onChange={(e) => setEdMedication(e.target.value)}
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Approval Amount</Label>
                      <Input
                        id="app_amount"
                        placeholder="Enter Approval Amount"
                        type="number"
                        value={edApproval}
                        onChange={(e) => {
                          const approvalValue = e.target.value;
                          setEdApproval(approvalValue);
                          setEdCommission((approvalValue * 0.15).toFixed(2));
                        }}
                      />
                    </Col>

                    <Col xs={6} className='mb-2'>
                      <Label>Commission</Label>
                      <Input
                        id="comm_amount"
                        placeholder="Commission"
                        type="number"
                        value={edCommission}
                        onChange={(e) => setEdCommission(e.target.value)}
                      />
                    </Col>

                    <Col xs={12} className='mb-2 text-end'>
                      <Button color='primary' disabled={loading}>
                        Update Patient {loading && <Spinner size="sm" />}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>

            <Modal
              centered
              scrollable
              size="lg"
              isOpen={modalOpen}
              toggle={() => closeModal()}
            >
              <ModalHeader toggle={() => closeModal()}>
                Update Payment Status
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={handleStatusUpdate}>
                  <Row>
                    <Col xs={12} className='mb-2'>
                      <Label>Status</Label>
                      <Input
                        type="select"
                        value={newStatus}
                        onChange={(e) => setNewStatus(e.target.value)}
                        required
                      >
                        <option value="0">Unpaid</option>
                        <option value="1">Paid</option>
                      </Input>
                    </Col>
                    <Col xs={12} className='mb-2 text-end'>
                      <Button color='primary' type="submit" loading={loading}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>

            <Modal
              centered
              scrollable
              size="lg"
              isOpen={edModalOpen}
              toggle={() => edCloseModal()}
            >
              <ModalHeader toggle={() => edCloseModal()}>
                Update Payment Status
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={handleEdStatusUpdate}>
                  <Row>
                    <Col xs={12} className='mb-2'>
                      <Label>Status</Label>
                      <Input
                        type="select"
                        value={edNewStatus}
                        onChange={(e) => setEdNewStatus(e.target.value)}
                        required
                      >
                        <option value="0">Unpaid</option>
                        <option value="1">Paid</option>
                      </Input>
                    </Col>
                    <Col xs={12} className='mb-2 text-end'>
                      <Button color='primary' type="submit" loading={loading}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Page>
      <Footer />
    </div>
  );
};

export default PatientView;
