/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Label, Form, Spinner, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { FaFilePdf, FaUnity, FaSearch } from 'react-icons/fa';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { BsFillArrowRightSquareFill, BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const PatientList = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [patientModal, setPatientModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState('');
  const [channel, setChannel] = useState('');
  const [hospital, setHospital] = useState('');
  const [register, setRegister] = useState(1);

  const [channels, setChannels] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_patient_list_handler();
    getChannels();
    getHospitals();
  }, []);

  const get_patient_list_handler = () => {
    setLoading(true);
    setTableData('');
    setFilterData('');

    axios.post(mainurl + 'Admin/patient_list')
      .then(function (response) {
        let list = response.data.map((row, index) => {
          let activity = row.status === '0' ? 'Inactive' : 'Active';

          const formatDate = (date) => {
            if (!date || date === "0000-00-00") {
              return "-";
            }
            const parsedDate = new Date(date);
            return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
          };

          return (
            {
              sl: index + 1,
              PatientName: row?.patient_name ?? '',
              Contact: '+91 ' + row?.phone_no ?? '',
              Email: row?.email_id ?? '',
              Gender: row?.gender ?? '',
              Dob: formatDate(row.dob),
              // Address: row.address,
              // Status: <span style={{ color: row.status === '0' ? 'red' : 'yellowgreen' }}>{activity}</span>,
              Action: <>
                <BsFillArrowRightSquareFill className='cursor ms-1' onClick={() => navigate(`/PatientView/${row.patient_id}`)} size={25} color="#1A73E8" />
              </>,
            }
          )
        })

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const getChannels = async () => {
    try {
      const response = await axios.get(mainurl + 'Admin/channel_list');
      setChannels(response.data);
    } catch (error) {
      console.log("Error fetching channels:", error);
    }
  }

  const getHospitals = async () => {
    try {
      const response = await axios.get(mainurl + 'Admin/hospital_master_list');
      setHospitals(response.data);
    } catch (error) {
      console.log("Error fetching hospitals:", error);
    }
  }

  const patient_add_handler = (e) => {
    e.preventDefault();
    setLoading(true);
    let postData = {
      "patient_name": name,
      "phone_no": contact,
      "email_id": email,
      "gender": gender,
      "dob": dob,
      "address": "",
      "status": 1
    };

    // console.log(postData);

    axios({
      method: 'post',
      url: mainurl + 'Admin/patient_add',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        setLoading(false);
        setPatientModal(false);
        let notify = notification({ message: "Patient Added Successfully", type: 'success' });
        notify();
        get_patient_list_handler();
        setName('');
        setContact('');
        setEmail('');
        setGender('');
        setDob('');

      }).catch(error => {
        setLoading(false);
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
  }

  const filter_data_handler = (e) => {
    let filterData = tableData.filter(item => {
      const query = e.target.value.toLowerCase();
      console.log(query);
      return (
        item?.PatientName?.toLowerCase().indexOf(query) >= 0 ||
        item?.Contact?.toLowerCase().indexOf(query) >= 0 ||
        item?.Dob?.toLowerCase().indexOf(query) >= 0 ||
        item?.Email?.toLowerCase().indexOf(query) >= 0
      )
    })
    setFilterData(filterData);
  }

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={6} className='mb-1'>
            <Title pageTitle="Patient List" />
          </Col>
          <Col md={3} className='text-end'>
            <Button className='text-white' color='primary' onClick={() => setPatientModal(true)} > <FaUnity size={20} />  Add Patient </Button>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-1'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Patient Name',
                      selector: row => row.PatientName,
                    },
                    {
                      name: 'Contact',
                      selector: row => row.Contact,
                      wrap: true
                    },
                    {
                      name: 'Email',
                      selector: row => row.Email,
                      wrap: true,
                      width: '200px'
                    },
                    {
                      name: 'Gender',
                      selector: row => row.Gender,
                      wrap: true
                    },
                    {
                      name: 'DOB',
                      selector: row => row.Dob,
                      wrap: true
                    },
                    // {
                    //   name: 'Address',
                    //   selector: row => row.Address,
                    //   wrap: true
                    // },
                    // {
                    //   name: 'Status',
                    //   selector: row => row.Status,
                    //   wrap: true
                    // },
                    {
                      name: 'Action',
                      selector: row => row.Action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={patientModal}
          toggle={() => setPatientModal(!patientModal)}
        >
          <ModalHeader toggle={() => setPatientModal(!patientModal)}>
            Add New Patient
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={patient_add_handler}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label for="patient_name"> Patient Name* </Label>
                  <Input id="name" placeholder="Patient Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </Col>
                {/* <Col xs={6} className='mb-2'>
                  <Label> Assign Channel* </Label>
                  <Input id="channel" name="channel" placeholder="Channel" type="select" value={channel} style={{ fontSize: '14px' }} onChange={(e) => setChannel(e.target.value)} required >
                    <option value=""> --Select Channel-- </option>
                    {channels.map((ch) => (<option key={ch.channel_header_id} value={ch.channel_header_id}>{ch.name}</option>))}
                  </Input>
                </Col> */}
                {/* <Col xs={6} className='mb-2'>
                  <Label> Assign Hospital* </Label>
                  <Input id="hospital" name="hospital" placeholder="Hospital" type="select" value={hospital} style={{ fontSize: '14px' }} onChange={(e) => setHospital(e.target.value)} required >
                    <option value=""> --Select Hospital-- </option>
                    {hospitals.map((ch) => (<option key={ch.hospital_id} value={ch.hospital_id}>{ch.name}</option>))}
                  </Input>
                </Col> */}
                <Col xs={6} className='mb-2'>
                  <Label for="contact"> Contact No* </Label>
                  <Input id="contact" placeholder="Contact Number" type="number" value={contact} onChange={(e) => setContact(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="email"> Email </Label>
                  <Input id="email" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label> Gender* </Label>
                  <Input id="gender" name="gender" placeholder="Gender" type="select" value={gender} style={{ fontSize: '14px' }} onChange={(e) => setGender(e.target.value)} required >
                    <option value=""> --Select Gender-- </option>
                    <option value="Male"> Male </option>
                    <option value="Female"> Female </option>
                    <option value="Others"> Others </option>
                  </Input>
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="dob"> Date of Birth* </Label>
                  <Input id="dob" placeholder="Date of Birth" type="date" value={dob} onChange={(e) => setDob(e.target.value)} required />
                </Col>
                {/* <Col xs={12} className='mb-2'>
                  <Label for="address"> Address </Label>
                  <Input id="address" placeholder="Address" type="textarea" value={address} onChange={(e) => setAddress(e.target.value)} />
                </Col> */}
                <Col xs={12} className='mb-2'>
                  <b>Note: </b> (*) Mark field are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page>
      <Footer />
    </div>
  )
}

export default PatientList;