/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Label, Form, Spinner, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { FaFilePdf, FaUnity, FaSearch } from 'react-icons/fa';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { BsFillArrowRightSquareFill, BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const ChannelList = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [channelModal, setChannelModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [commission, setCommission] = useState('');
  const [payment, setPayment] = useState('');
  const [register, setRegister] = useState(1);

  const [channelEditModal, setChannelEditModal] = useState(false);
  const [channelId, setChannelId] = useState('');
  const [edName, setEdName] = useState('');
  const [edContact, setEdContact] = useState('');
  const [edEmail, setEdEmail] = useState('');
  const [edGender, setEdGender] = useState('');
  const [edCommission, setEdCommission] = useState('');
  const [edPayment, setEdPayment] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_channel_list_handler();
  }, []);

  const get_channel_list_handler = () => {
    setLoading(true);
    setTableData('');
    setFilterData('');

    axios.post(mainurl + 'Admin/channel_list')
      .then(function (response) {
        let list = response.data.map((row, index) => {
          let activity = row.status === '0' ? 'Inactive' : 'Active';
          return (
            {
              sl: index + 1,
              ChannelName: row.name,
              Contact: '+91 ' + row.phone_no,
              Email: row.email,
              Gender: row.gender,
              Commission: "₹" + row.commission,
              PaymentCycle: row.channel_payment_cy + ' Days',
              // Status: <span style={{ color: row.status === '0' ? 'red' : 'yellowgreen' }}>{activity}</span>,
              Action: <>
                <BsPencilSquare className='cursor' color='#fe9300' size={22} onClick={() => open_edit_handler(row)} />
                <BsFillTrashFill className='cursor ms-1' color='#fb3a3b' size={22} onClick={() => delete_handler(row)} />
                <BsFillArrowRightSquareFill className='cursor ms-1' onClick={() => navigate(`/ChannelView/${row.channel_header_id}`)} size={22} color="#1A73E8" />
              </>,
            }
          )
        })

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const channel_add_handler = (e) => {
    e.preventDefault();
    setLoading(true);
    let postData = {
      "name": name,
      "phone_no": contact,
      "email": email,
      "gender": gender,
      "commission": commission,
      "channel_payment_cy": payment,
      "status": 1
    };

    // console.log(postData);

    axios({
      method: 'post',
      url: mainurl + 'Admin/channel_add',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        setLoading(false);
        setChannelModal(false);
        let notify = notification({ message: "Channel Added Successfully", type: 'success' });
        notify();
        get_channel_list_handler();
        setName('');
        setContact('');
        setEmail('');
        setGender('');
        setCommission('');

      }).catch(error => {
        setLoading(false);
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
  }

  const delete_handler = (row) => {
    confirmAlert({
      title: 'Please Confirm',
      message: 'Are you sure you want to delete ' + row.name + ' ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let postData = { "channel_header_id": row.channel_header_id, "status": 0 };

            axios({
              method: 'post',
              url: mainurl + 'Admin/channel_status_update',
              data: postData,
              headers: { 'Content-Type': 'application/JSON', }
            })
              .then(function (response) {

                let notify = notification({ message: "Channel deleted successfully", type: 'success' });
                notify();
                get_channel_list_handler();

              }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
              })

          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const open_edit_handler = (row) => {
    setChannelId(row.channel_header_id)
    setEdName(row.name);
    setEdContact(row.phone_no);
    setEdEmail(row.email);
    setEdGender(row.gender);
    setEdCommission(row.commission);
    setEdPayment(row.channel_payment_cy);
    setChannelEditModal(true);
  }

  const channel_edit_handler = (e) => {
    e.preventDefault();
    setLoading(true);

    let postData = {
      // "name": edName,
      "phone_no": edContact,
      "email": edEmail,
      "gender": edGender,
      "commission": edCommission,
      "channel_payment_cy": edPayment,
      "status": 1,
      "channel_header_id": channelId
    };

    axios({
      method: 'post',
      url: mainurl + 'Admin/channel_edit',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        setLoading(false);
        setChannelEditModal(false);
        let notify = notification({ message: "Channel Updated successfully", type: 'success' });
        notify();
        get_channel_list_handler();

      }).catch(error => {
        setLoading(false);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
  }

  const filter_data_handler = (e) => {
    let filterData = tableData.filter(item => {
      const query = e.target.value.toLowerCase();
      console.log(query);
      return (
        item?.ChannelName?.toLowerCase().indexOf(query) >= 0 ||
        item?.Contact?.toLowerCase().indexOf(query) >= 0 ||
        item?.Commission?.toLowerCase().indexOf(query) >= 0 ||
        item?.Email?.toLowerCase().indexOf(query) >= 0 ||
        item?.PaymentCycle?.toLowerCase().indexOf(query) >= 0
      )
    })
    setFilterData(filterData);
  }

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={6} className='mb-1'>
            <Title pageTitle="Channel List" />
          </Col>
          <Col md={3} className='text-end'>
            <Button className='text-white' color='primary' onClick={() => setChannelModal(true)} > <FaUnity size={20} />  Add Channel </Button>
          </Col>
          <Col xs={3} className='mb-1 text-end'>
            <InputGroup>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input size="sm" type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-1'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Channel Name',
                      selector: row => row.ChannelName,
                    },
                    {
                      name: 'Contact',
                      selector: row => row.Contact,
                      wrap: true
                    },
                    {
                      name: 'Email',
                      selector: row => row.Email,
                      wrap: true,
                      width: '200px'
                    },
                    {
                      name: 'Gender',
                      selector: row => row.Gender,
                      wrap: true
                    },
                    {
                      name: 'Commission',
                      selector: row => row.Commission,
                      wrap: true
                    },
                    {
                      name: 'Payment Cycle',
                      selector: row => row.PaymentCycle,
                      wrap: true
                    },
                    // {
                    //   name: 'Status',
                    //   selector: row => row.Status,
                    //   wrap: true
                    // },
                    {
                      name: 'Action',
                      selector: row => row.Action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={channelModal}
          toggle={() => setChannelModal(!channelModal)}
        >
          <ModalHeader toggle={() => setChannelModal(!channelModal)}>
            Add New Channel
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={channel_add_handler}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label for="channel_name"> Channel Name* </Label>
                  <Input id="name" placeholder="Channel Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="contact"> Contact No* </Label>
                  <Input id="contact" placeholder="Contact Number" type="number" value={contact} onChange={(e) => setContact(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="email"> Email </Label>
                  <Input id="email" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label> Gender* </Label>
                  <Input id="gender" name="gender" placeholder="Gender" type="select" value={gender} style={{ fontSize: '14px' }} onChange={(e) => setGender(e.target.value)} required >
                    <option value=""> --Select Gender-- </option>
                    <option value="Male"> Male </option>
                    <option value="Female"> Female </option>
                  </Input>
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="commission"> Referral Amount* </Label>
                  <Input id="commission" placeholder="Referral Amount" type="number" value={commission} onChange={(e) => setCommission(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="payment"> Payment Cycle* </Label>
                  <Input id="payment" placeholder="Payment Cycle (in Days)" type="text" value={payment} onChange={(e) => setPayment(e.target.value)} required />
                </Col>
                <Col xs={12} className='mb-2'>
                  <b>Note: </b> (*) Mark field are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={channelEditModal}
          toggle={() => setChannelEditModal(!channelEditModal)}
        >
          <ModalHeader toggle={() => setChannelEditModal(!channelEditModal)}>
            Add New Channel
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={channel_edit_handler}>
              <Row>
                {/* <Col xs={12} className='mb-2'>
                  <Label for="channel_name"> Channel Name* </Label>
                  <Input id="name" placeholder="Channel Name" type="text" value={edName} onChange={(e) => setEdName(e.target.value)} required />
                </Col> */}
                <Col xs={6} className='mb-2'>
                  <Label for="contact"> Contact No* </Label>
                  <Input id="contact" placeholder="Contact Number" type="number" value={edContact} onChange={(e) => setEdContact(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="email"> Email* </Label>
                  <Input id="email" placeholder="Email" type="email" value={edEmail} onChange={(e) => setEdEmail(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label> Gender* </Label>
                  <Input id="gender" name="gender" placeholder="Gender" type="select" value={edGender} style={{ fontSize: '14px' }} onChange={(e) => setEdGender(e.target.value)} required >
                    <option value=""> --Select Gender-- </option>
                    <option value="Male"> Male </option>
                    <option value="Female"> Female </option>
                    <option value="Others"> Others </option>
                  </Input>
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="commission"> Referral Amount* </Label>
                  <Input id="commission" placeholder="Referral Amount" type="number" value={edCommission} onChange={(e) => setEdCommission(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="payment"> Payment Cycle* </Label>
                  <Input id="payment" placeholder="Payment Cycle (in Days)" type="text" value={edPayment} onChange={(e) => setEdPayment(e.target.value)} required />
                </Col>
                <Col xs={12} className='mb-2'>
                  <b>Note: </b> (*) Mark field are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading} > UPDATE {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page>
      <Footer />
    </div>
  )
}

export default ChannelList;