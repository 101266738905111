import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { BsPencilSquare } from 'react-icons/bs';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import {
  Row, Col, Card, CardBody, Button, Label,
  CardHeader, ModalBody, Modal, ModalHeader,
  Input, ModalFooter, Form
} from 'reactstrap';

import { FaPlus, FaEdit, FaClipboardList } from 'react-icons/fa';

const ChannelView = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [channelData, setChannelData] = useState(null);
  const [patientData, setPatientData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [channelPaymentId, setChannelPaymentId] = useState('');

  const { channel_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Channel ID from URL:", channel_id);
    get_channel_list_handler();
    getPatientData();
  }, []);

  const get_channel_list_handler = () => {
    setLoading(true);

    const requestData = {
      channel_id: channel_id
    };

    axios.post(mainurl + 'Admin/channel_view', requestData)
      .then(function (response) {
        console.log("API Response:", response.data);
        const channelDetails = response.data[0];
        setChannelData(channelDetails);
      })
      .catch(error => {
        // setLoading(false);
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getPatientData = async () => {
    setLoading(true);
    // const response = await axios.post(mainurl + 'Admin/channel_wish_patient', { channel_id });
    axios.post(mainurl + 'Admin/channel_wish_patient', { channel_id })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          let status = row.channal_amount_status === '0' ? 'Unpaid' : 'Paid';
          let moa;
          const modeOfAdmission = Number(row.mode_of_admission);

          if (modeOfAdmission === 1) {
            moa = "Swasthya Sathi";
          } else if (modeOfAdmission === 2) {
            moa = "Insurance Policy";
          } else {
            moa = "Cash";
          }

          const formatDate = (date) => {
            if (!date || date === "0000-00-00") {
              return "-";
            }
            const parsedDate = new Date(date);
            return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
          };

          return (
            {
              sl: index + 1,
              PatientName: row.patient_name,
              AdmitDate: formatDate(row.admit_date),
              DischargeDate: formatDate(row.discharge_date),
              IcuDays: row.no_days_icu,
              GenDays: row.no_days_gen,
              OthDays: row.no_days_others,
              ModeofAdmission: moa,
              ApprovalAmount: '₹' + row.channel_amount,
              Status: status,
              Action: row.channal_amount_status === '0' ? (
                <button
                  className="text-white me-2"
                  style={{ backgroundColor: '#fe9300', color: '#fff', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
                  onClick={() => openEditModal(row)}
                >
                  Pay Now
                </button>
              ) : (
                <button
                  style={{ backgroundColor: '#28a745', color: '#fff', border: 'none', padding: '5px 10px' }}
                  disabled
                >
                  Paid
                </button>
              )
            }
          )
        })
        // const transformedData = transformPatientData(response.data);
        setPatientData(list);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const openEditModal = (row) => {
    setChannelPaymentId(row.hpc_map_id);
    setNewStatus(row.channal_amount_status);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleStatusUpdate = async () => {
    setLoading(true);
    let postData = {
      "hpc_map_id": channelPaymentId ?? 0,
      "channal_amount_status": newStatus
    };
    axios({
      method: 'POST',
      url: mainurl + 'Admin/channel_payment_status_update',
      data: postData,
      headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {

      setLoading(false);
      closeModal();
      let notify = notification({ message: "Status updated successfully", type: 'success' });
      notify();
      setChannelPaymentId('');
      setNewStatus('');
      getPatientData();

    }).catch(error => {
      setLoading(false);
      console.log(error);
      let notify = notification({ message: "Failed! Try Again", type: 'error' });
      notify();
    })
  };

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Channel Details View" />
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Channel Information</CardHeader>
              <CardBody>
                <Row>
                  {channelData ? (
                    <>
                      <Label sm={3}><b>Channel ID: </b></Label>
                      <Label sm={3}>{channelData.channel_header_id}</Label>

                      <Label sm={3}><b>Name:</b></Label>
                      <Label sm={3}>{channelData.name}</Label>

                      <Label sm={3}><b>Contact: </b></Label>
                      <Label sm={3}>{channelData.phone_no}</Label>

                      <Label sm={3}><b>Email: </b></Label>
                      <Label sm={3}>{channelData.email}</Label>

                      <Label sm={3}><b>Gender:</b></Label>
                      <Label sm={3}>{channelData.gender}</Label>

                      <Label sm={3}><b>Referral Amount per Patient: </b></Label>
                      <Label sm={3}>₹ {channelData.commission}</Label>

                      <Label sm={3}><b>Payment Cycle: </b></Label>
                      <Label sm={3}>{channelData.channel_payment_cy}</Label>

                      <Label sm={3}><b>Status: </b></Label>
                      <Label sm={3}>{channelData.status === '0' ? 'Inactive' : 'Active'}</Label>
                    </>
                  ) : (
                    <Label sm={12}>Loading channel information...</Label>
                  )}

                </Row>
              </CardBody>
            </Card>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Patient Referral Summary</CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <DataTable
                      className='mb-3'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Patient Name',
                          selector: row => row.PatientName
                        },
                        {
                          name: 'Mode of Admission',
                          selector: row => row.ModeofAdmission,
                          wrap: true
                        },
                        {
                          name: 'Admit Date',
                          selector: row => row.AdmitDate,
                          wrap: true
                        },
                        {
                          name: 'Discharge Date',
                          selector: row => row.DischargeDate,
                          wrap: true,
                          width: '200px'
                        },
                        {
                          name: 'ICU Days',
                          selector: row => row.IcuDays,
                          wrap: true
                        },
                        {
                          name: 'Gen Days',
                          selector: row => row.GenDays,
                          wrap: true
                        },
                        {
                          name: 'Medicine / Investigation',
                          selector: row => row.OthDays,
                          wrap: true
                        },
                        {
                          name: 'Referral Amount',
                          selector: row => row.ApprovalAmount,
                          wrap: true
                        },
                        // {
                        //   name: 'Status',
                        //   selector: row => row.Status,
                        //   wrap: true,
                        //   cell: row => (
                        //     <span style={{ color: row.Status === 'Unpaid' ? 'red' : 'green' }}>
                        //       {row.Status}
                        //     </span>
                        //   )
                        // },
                        {
                          name: 'Action',
                          selector: row => row.Action,
                        },
                      ]}
                      data={patientData}
                      pagination
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
      <Modal
        centered
        scrollable
        size="lg"
        isOpen={modalOpen}
        toggle={() => closeModal()}
      >
        <ModalHeader toggle={() => closeModal()}>
          Update Payment Status
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleStatusUpdate}>
            <Row>
              <Col xs={12} className='mb-2'>
                <Label>Status</Label>
                <Input
                  type="select"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  required
                >
                  <option value="0">Unpaid</option>
                  <option value="1">Paid</option>
                </Input>
              </Col>
              <Col xs={12} className='mb-2 text-end'>
                <Button color='primary' type="submit" loading={loading}>
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Footer />

    </div>
  )
}

export default ChannelView;