import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import moment from 'moment';
import axios from 'axios';
import { mainurl, fileurl } from '../../MainUrl';
import { notification } from '../../component/hocs/notification';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

import { BsPencilSquare } from 'react-icons/bs';

import {
  Row, Col, Card, CardBody, Button, Label,
  CardHeader, Input, Modal, ModalHeader, ModalBody,
  CardTitle, Form,
} from 'reactstrap';

import { FaPlus, FaEdit, FaClipboardList } from 'react-icons/fa';

const HospitalView = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [hospitalData, setHospitalData] = useState(null);
  const [patientData, setPatientData] = useState([]);
  const [kycData, setKycData] = useState([]);

  const { hospital_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Hospital ID from URL:", hospital_id);
    get_hospital_list_handler();
    getPatientData();
    getKycData();
  }, []);

  const get_hospital_list_handler = () => {
    setLoading(true);

    const requestData = {
      hospital_id: hospital_id
    };

    axios.post(mainurl + 'Admin/hospital_details_view', requestData)
      .then(function (response) {
        console.log("API Response:", response.data);
        const hospitalDetails = response.data[0];
        setHospitalData(hospitalDetails);
      })
      .catch(error => {
        // setLoading(false);
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getPatientData = async () => {
    setLoading(true);
    // const response = await axios.post(mainurl + 'Admin/hospital_wish_patient', { hospital_id });
    axios.post(mainurl + 'Admin/hospital_wish_patient', { hospital_id })
      .then(function (response) {
        let list = response.data.map((row, index) => {
          let moa;
          const modeOfAdmission = Number(row.mode_of_admission);

          if (modeOfAdmission === 1) {
            moa = "Swasthya Sathi";
          } else if (modeOfAdmission === 2) {
            moa = "Insurance Policy";
          } else {
            moa = "Cash";
          }

          const formatDate = (date) => {
            if (!date || date === "0000-00-00") {
              return "-";
            }
            const parsedDate = new Date(date);
            return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
          };

          return (
            {
              sl: index + 1,
              PatientName: row.patient_name,
              AdmitDate: formatDate(row.admit_date),
              DischargeDate: formatDate(row.discharge_date),
              IcuDays: row.no_days_icu,
              GenDays: row.no_days_gen,
              OthDays: row.no_days_others,
              ModeofAdmission: moa,
              ApprovalAmount: '₹' + row.hospital_approval_amount,
              Commission: '₹' + row.hospital_commission,
            }
          )
        })
        // const transformedData = transformPatientData(response.data);
        setPatientData(list);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const getKycData = async () => {
    try {
      const response = await axios.post(mainurl + 'Admin/hospital_wish_kyc', { hospital_id });
      setKycData(transformKycData(response.data));
    } catch (error) {
      console.log(error);
      // notification({ message: 'Failed to fetch kyc data!', type: 'error' })();
    }
  };

  const transformKycData = (data) => {
    return data.map((row, index) => ({
      sl: index + 1,
      created_on: row.created_on,
      kyc_name: row.kyc_name,
      kyc_image: row.kyc_image,
    }));
  };

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={12} className='mb-3'>
            <Title pageTitle="Hospital Details View" />
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className='mb-3'>
              <CardHeader>Hospital Information</CardHeader>
              <CardBody>
                <Row>
                  {hospitalData ? (
                    <>
                      <Label sm={3}><b>Hospital ID: </b></Label>
                      <Label sm={3}>{hospitalData.hospital_id}</Label>

                      <Label sm={3}><b>Name:</b></Label>
                      <Label sm={3}>{hospitalData.name}</Label>

                      <Label sm={3}><b>Address:</b></Label>
                      <Label sm={3}>{hospitalData.address}</Label>

                      <Label sm={3}><b>Contact Number: </b></Label>
                      <Label sm={3}>{hospitalData.phone_no}</Label>

                      <Label sm={3}><b>Email: </b></Label>
                      <Label sm={3}>{hospitalData.email}</Label>

                      <Label sm={3}><b>GST Number: </b></Label>
                      <Label sm={3}>{hospitalData.gst_no}</Label>

                      <Label sm={3}><b>Commission: </b></Label>
                      <Label sm={3}>{hospitalData.commission}%</Label>

                      <Label sm={3}><b>Status: </b></Label>
                      <Label sm={3}>{hospitalData.status === '0' ? 'Inactive' : 'Active'}</Label>
                    </>
                  ) : (
                    <Label sm={12}>Loading hospital information...</Label>
                  )}

                </Row>
              </CardBody>
            </Card>

            <Card color="primary" outline className="mb-3">
              <CardHeader>Hospital KYC Information</CardHeader>
              <CardBody>
                <DataTable
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Uploaded On',
                      selector: row => row.created_on,
                      wrap: true
                    },
                    {
                      name: 'KYC Name',
                      selector: row => row.kyc_name,
                      wrap: true
                    },
                    {
                      name: 'KYC Image',
                      selector: row => row.kyc_image,
                      wrap: true
                    },
                  ]}
                  data={kycData}
                  pagination
                />
              </CardBody>
            </Card>

            <Card color="primary" outline className='mb-3'>
              <CardHeader>Patient Information</CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <DataTable
                      className='mb-3'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Patient Name',
                          selector: row => row.PatientName
                        },
                        {
                          name: 'Mode of Admission',
                          selector: row => row.ModeofAdmission,
                          wrap: true
                        },
                        {
                          name: 'Admit Date',
                          selector: row => row.AdmitDate,
                          wrap: true
                        },
                        {
                          name: 'Discharge Date',
                          selector: row => row.DischargeDate,
                          wrap: true,
                          width: '200px'
                        },
                        {
                          name: 'ICU Days',
                          selector: row => row.IcuDays,
                          wrap: true
                        },
                        {
                          name: 'Gen Days',
                          selector: row => row.GenDays,
                          wrap: true
                        },
                        {
                          name: 'Medicine / Investigation',
                          selector: row => row.OthDays,
                          wrap: true
                        },
                        {
                          name: 'Approval Amount',
                          selector: row => row.ApprovalAmount,
                          wrap: true
                        },
                        {
                          name: 'Commission',
                          selector: row => row.Commission,
                          wrap: true
                        },
                      ]}
                      data={patientData}
                      pagination
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Page>

      <Footer />
    </div>
  )
}

export default HospitalView;