import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './../component/layout/Header';
import Footer from './../component/layout/Footer';
import Page from '../component/layout/Page';

import axios from 'axios';
import { mainurl } from '../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../style/color';
import { notification } from '../component/hocs/notification';

import {
  Row, Col, Card, CardHeader, CardBody, ModalHeader, ModalBody, Input, Label, Form, Modal, Button
} from 'reactstrap';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const Home = () => {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [channelTableData, setChannelTableData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [channelPaymentId, setChannelPaymentId] = useState('');

  const [dashboardData, setDashboardData] = useState({
    active_hospital: 0,
    active_channel: 0,
    active_patient: 0,
    total_payment_hospital: 0,
    total_payment_channel: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_dashboard_counts_handler();
    get_hospital_payments_handler();
    get_channel_payments_handler();
    console.log("log_data", log_data);
    console.log("log_data.aktc_role_id", log_data.aktc_role_id);

  }, []);

  const get_hospital_payments_handler = () => {
    setLoading(true);
    setTableData('');

    axios.post(mainurl + 'Admin/payment_list')
      .then(function (response) {
        console.log(response.data);
        const list = response.data.map((row, index) => {
          let status = row.status === '0' ? 'Unpaid' : 'Paid';
          let moa;
          const modeOfAdmission = Number(row.mode_of_admission);

          if (modeOfAdmission === 1) {
            moa = "Swasthya Sathi";
          } else if (modeOfAdmission === 2) {
            moa = "Insurance Policy";
          } else {
            moa = "Cash";
          }

          let formattedDate;
          if (row.discharge_date === "0000-00-00") {
            formattedDate = "-";
          } else {
            const dischargeDate = new Date(row.discharge_date);
            formattedDate = `${dischargeDate.getDate().toString().padStart(2, '0')}-${(dischargeDate.getMonth() + 1).toString().padStart(2, '0')}-${dischargeDate.getFullYear()}`;
          }

          return (
            {
              sl: index + 1,
              ModeofAdmission: moa,
              IdNumber: row.policy_number,
              PatientName: row.patient_name,
              DischargeDate: formattedDate,
              HospitalName: row.hospital_name,
              ApprovedAmount: '₹' + row.hospital_approval_amount,
              Commission: '₹' + row.hospital_commission,
              ChannelName: row.channel_name,
            }
          )
        })
        setTableData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const get_channel_payments_handler = () => {
    setLoading(true);
    setChannelTableData('');

    axios.post(mainurl + 'Admin/channel_payment_list')
      .then(function (response) {
        // console.log(response.data);
        const list = response.data
          .filter(row => row.channal_amount_status === '0')
          .map((row, index) => {
            let status = row.status === '0' ? 'Unpaid' : 'Paid';

            let formattedDate;
            if (row.channel_due_date === "0000-00-00") {
              formattedDate = "-";
            } else {
              const channelDueDate = new Date(row.channel_due_date);
              formattedDate = `${channelDueDate.getDate().toString().padStart(2, '0')}-${(channelDueDate.getMonth() + 1).toString().padStart(2, '0')}-${channelDueDate.getFullYear()}`;
            }

            return (
              {
                sl: index + 1,
                PaymentDate: formattedDate,
                ChannelName: row.name,
                ChannelContact: row.phone_no,
                ChannelAmount: '₹' + row.commission,
                ChannelCycle: row.channel_payment_cy + " Days",
                Status: status,
                Action: row.channal_amount_status === '0' ? (
                  <button
                    className="text-white me-2"
                    style={{ backgroundColor: '#fe9300', color: '#fff', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
                    onClick={() => openEditModal(row)}
                  >
                    Pay Now
                  </button>
                ) : (
                  <button
                    style={{ backgroundColor: '#28a745', color: '#fff', border: 'none', padding: '5px 10px' }}
                    disabled
                  >
                    Paid
                  </button>
                )
              }
            )
          })
        setChannelTableData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const get_dashboard_counts_handler = () => {
    axios.post(mainurl + 'Admin/dashboard_count')
      .then(function (response) {
        if (response.data && response.data.length > 0) {
          const data = response.data[0];
          setDashboardData({
            active_channel: data?.active_channel ?? 0,
            active_patient: data?.active_patient ?? 0,
            total_patient: data?.total_patient ?? 0,
            total_payment_hospital: data?.total_payment_hospital ?? 0,
            total_active_payment_channel: data?.total_active_payment_channel ?? 0,
            today_active_payment_channel: data?.today_active_payment_channel ?? 0,
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error('Failed to fetch dashboard data.');
      });
  };

  const openEditModal = (row) => {
    setChannelPaymentId(row.hpc_map_id);
    setNewStatus(row.channal_amount_status);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleStatusUpdate = async () => {
    setLoading(true);
    let postData = {
      "hpc_map_id": channelPaymentId ?? 0,
      "channal_amount_status": newStatus
    };
    axios({
      method: 'POST',
      url: mainurl + 'Admin/channel_payment_status_update',
      data: postData,
      headers: { 'Content-Type': 'application/json' }
    }).then(function (response) {

      setLoading(false);
      closeModal();
      let notify = notification({ message: "Status updated successfully", type: 'success' });
      notify();
      setChannelPaymentId('');
      setNewStatus('');
      get_channel_payments_handler();

    }).catch(error => {
      setLoading(false);
      console.log(error);
      let notify = notification({ message: "Failed! Try Again", type: 'error' });
      notify();
    })
  };

  return (
    <div>
      <Header />
      <Page>
        <Row >
          <Col xs={12} className="mb-3">
            <div className='d-flex justify-content-between'>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Today's Liabilities</div>
                <div className='fs-20 text-primary'><b>₹{dashboardData?.today_active_payment_channel ?? 0}</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Total Liabilities</div>
                <div className='fs-20 text-primary'><b>₹{dashboardData?.total_active_payment_channel ?? 0}</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Total Channels</div>
                <div className='fs-20 text-primary'><b>{dashboardData?.active_channel ?? 0}</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Total Patients</div>
                <div className='fs-20 text-primary'><b>{dashboardData?.total_patient ?? 0}</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Undischarged Patients</div>
                <div className='fs-20 text-primary'><b>{dashboardData?.active_patient ?? 0}</b></div>
              </div>
              <div className='hBox'>
                <div className='text-muted fs-bold'>Total Approval Amount</div>
                <div className='fs-20 text-primary'><b>₹{dashboardData?.total_payment_hospital ?? 0}</b></div>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <Card color="primary" outline className="mb-3">
              <CardHeader>Hospital Approval Summary</CardHeader>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <DataTable
                      className='mb-1'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Admission Type',
                          selector: row => row.ModeofAdmission,
                        },
                        {
                          name: 'ID Number',
                          selector: row => row.IdNumber,
                          wrap: true
                        },
                        {
                          name: 'Patient Name',
                          selector: row => row.PatientName,
                          wrap: true,
                          width: '200px'
                        },
                        {
                          name: 'Discharge Date',
                          selector: row => row.DischargeDate,
                          wrap: true,
                          width: '200px'
                        },
                        {
                          name: 'Hospital Name',
                          selector: row => row.HospitalName,
                          wrap: true
                        },
                        {
                          name: 'Approved Amount',
                          selector: row => row.ApprovedAmount,
                          wrap: true
                        },
                        {
                          name: 'Commission',
                          selector: row => row.Commission,
                          wrap: true
                        },
                        {
                          name: 'Channel Name',
                          selector: row => row.ChannelName,
                          wrap: true
                        },
                        // {
                        //   name: 'Status',
                        //   selector: row => row.Status,
                        //   wrap: true,
                        //   cell: row => (
                        //     <span style={{ color: row.Status === 'Unpaid' ? 'red' : 'green' }}>
                        //       {row.Status}
                        //     </span>
                        //   )
                        // },
                      ]}
                      data={tableData}
                      highlightOnHover
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      progressPending={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card color="primary" outline className="mb-3">
              <CardHeader>Channel Payout Summary</CardHeader>
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <DataTable
                      className='mb-1'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Payout Due Date',
                          selector: row => row.PaymentDate,
                        },
                        // {
                        //   name: 'Payout Date',
                        //   selector: row => row.PaymentDate,
                        // },
                        {
                          name: 'Channel Name',
                          selector: row => row.ChannelName,
                        },
                        {
                          name: 'Channel Contact',
                          selector: row => row.ChannelContact,
                        },
                        {
                          name: 'Unpaid Referral Amount',
                          selector: row => row.ChannelAmount,
                          wrap: true
                        },
                        {
                          name: 'Payment Cycle',
                          selector: row => row.ChannelCycle,
                          wrap: true
                        },
                        // {
                        //   name: 'Status',
                        //   selector: row => row.Status,
                        //   wrap: true,
                        //   cell: row => (
                        //     <span style={{ color: row.Status === 'Unpaid' ? 'red' : 'green' }}>
                        //       {row.Status}
                        //     </span>
                        //   )
                        // },
                        {
                          name: 'Action',
                          selector: row => row.Action,
                        },
                      ]}
                      data={channelTableData}
                      highlightOnHover
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      progressPending={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          centered
          scrollable
          size="lg"
          isOpen={modalOpen}
          toggle={() => closeModal()}
        >
          <ModalHeader toggle={() => closeModal()}>
            Update Payment Status
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleStatusUpdate}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                    required
                  >
                    <option value="0">Unpaid</option>
                    <option value="1">Paid</option>
                  </Input>
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button color='primary' type="submit" loading={loading}>
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page>

      <Footer />
    </div>
  )
}

export default Home;