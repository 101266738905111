import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Logo from "./../../assets/img/logo.png";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { FaHome } from "react-icons/fa";
import { MdOutlineHome } from "react-icons/md";

function Header() {
  //redux (From store)
  const log_data = useSelector((state) => state.logged.loginData);

  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(1);
  const [menu, setMenu] = useState(false);
  const [m1, setM1] = useState(false);
  const [m2, setM2] = useState(false);
  const [m3, setM3] = useState(false);
  const [m4, setM4] = useState(false);
  const [m5, setM5] = useState(false);

  useEffect(() => {
    console.log(log_data);
    if (log_data.login !== true) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  }, []);

  function signOutClickHandler() {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }

  return (
    <>
      <div className="headerPage">
        <Row>
          <Col xs={9} className="p-2">
            {/* <Col xs={12} className="d-flex align-items-center justify-content-center borderBottom">
                        <img height="55px" src={logo} alt="logo" />
                        <h4 className='text-primary m-0'><b>AKTC Health Solutions</b></h4>
                    </Col> */}
            {/* <Col xs={12} className='borderBottom'> */}
            {/* ------------------ In Laptop Menu  -------------------- */}
            <div className="justify-content-start align-items-center h-100 d-none d-md-flex">
              <div className="" style={{ height: "40px", width: "25px" }}></div>
              <div className="d_menu" onClick={() => navigate("/home")}>
                Dashboard
              </div>
              <Dropdown
                isOpen={m1}
                onMouseEnter={() => setM1(true)}
                onMouseLeave={() => setM1(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m1 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Hospitals
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM1(false);
                    }}
                  >
                    <Link to="/HospitalList">Hospital List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={m2}
                onMouseEnter={() => setM2(true)}
                onMouseLeave={() => setM2(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m2 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Channels
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM2(false);
                    }}
                  >
                    <Link to="/ChannelList">Channel List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={m4}
                onMouseEnter={() => setM4(true)}
                onMouseLeave={() => setM4(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m4 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Patients
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM4(false);
                    }}
                  >
                    <Link to="/PatientList">Patient List</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={m5}
                onMouseEnter={() => setM5(true)}
                onMouseLeave={() => setM5(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m5 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Reports
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM5(false);
                    }}
                  >
                    <Link to="/HospitalPaymentReport">
                      Hospital Payment Report
                    </Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM5(false);
                    }}
                  >
                    <Link to="/ChannelPaymentReport">
                      Channel Payment Report
                    </Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM5(false);
                    }}
                  >
                    <Link to="/LiabilityReport">Liability Report</Link>
                  </div>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM5(false);
                    }}
                  >
                    <Link to="/AccountReport">Account Report</Link>
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={m3}
                onMouseEnter={() => setM3(true)}
                onMouseLeave={() => setM3(false)}
              >
                <DropdownToggle
                  className={`d_menu ${m3 && "bg-primary text-white"}`}
                  data-toggle="dropdown"
                  tag="span"
                >
                  Settings
                </DropdownToggle>
                <DropdownMenu>
                  <div
                    className="d_menuItem first"
                    onClick={() => {
                      setM3(false);
                    }}
                  >
                    <Link to="/ChangePassword">Change Password</Link>
                  </div>
                  <div className="d_menu" onClick={() => signOutClickHandler()}>
                    Signout
                  </div>
                </DropdownMenu>
              </Dropdown>

              {/* <div className="d_menu" onClick={() => signOutClickHandler()}>
                Signout
              </div> */}
            </div>
            {/* ------------------ In Mobile Menu  -------------------- */}
            <div className="d-flex d-md-none justify-content-start align-items-center h-100"></div>
          </Col>
          <Col xs={3} className="d-flex align-items-center justify-content-end">
            <img className="p-1" height="55px" src={Logo} alt="logo" />
            <h5 className="text-primary m-0 me-4">
              <b>AKTC Health Solutions</b>
            </h5>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Header;
