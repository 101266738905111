import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import FileBase64 from 'react-file-base64';

import {
  Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, Label, Form, Spinner, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { FaFilePdf, FaEdit, FaUnity, FaSearch } from 'react-icons/fa';

import { BsFillArrowRightSquareFill, BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { MdDeleteForever } from 'react-icons/md';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const HospitalList = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hospitalModal, setHospitalModal] = useState(false);
  const [hospitalKycModal, setHospitalKycModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [gstno, setGstno] = useState('');
  const [commission, setCommission] = useState('');
  const [register, setRegister] = useState(1);

  const [hospitalEditModal, setHospitalEditModal] = useState(false);
  const [hospitalId, setHospitalId] = useState('');
  const [edName, setEdName] = useState('');
  const [edAddress, setEdAddress] = useState('');
  const [edContact, setEdContact] = useState('');
  const [edEmail, setEdEmail] = useState('');
  const [edGstno, setEdGstno] = useState('');
  const [edCommission, setEdCommission] = useState('');

  const [hospitalName, setHospitalName] = useState([]);
  const [kycImages, setKycImages] = useState([]);
  const [kycExtension, setKycExtension] = useState([]);
  const [kycName, setKycName] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_hospital_list_handler();
    getHospitals();
  }, []);

  const get_hospital_list_handler = () => {
    setLoading(true);
    setTableData('');
    setFilterData('');

    axios.post(mainurl + 'Admin/hospital_master_list')
      .then(function (response) {
        let list = response.data.map((row, index) => {
          return (
            {
              sl: index + 1,
              HospitalName: row.name,
              Address: row.address,
              Contact: '+91 ' + row.phone_no,
              Email: row.email,
              Gstin: row.gst_no,
              Commission: row.commission + '%',
              Kyc: <FaFilePdf className='cursor' size={20} color="red" />,
              Action: <>
                <BsPencilSquare className='cursor' color='#fe9300' size={22} onClick={() => open_edit_handler(row)} />
                <BsFillTrashFill className='cursor ms-1' color='#fb3a3b' size={22} onClick={() => delete_handler(row)} />
                <BsFillArrowRightSquareFill className='cursor ms-1' onClick={() => navigate(`/HospitalView/${row.hospital_id}`)} size={25} color="#1A73E8" />
              </>,
            }
          )
        })

        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const hospital_add_handler = (e) => {
    e.preventDefault();
    setLoading(true);
    let postData = {
      "name": name,
      "address": address,
      "phone_no": contact,
      "email": email,
      "gst_no": gstno,
      "commission": commission,
      "status": 1
    };

    // console.log(postData);

    axios({
      method: 'post',
      url: mainurl + 'Admin/hospital_master_add',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        setLoading(false);
        setHospitalModal(false);
        let notify = notification({ message: "Hospital Added Successfully", type: 'success' });
        notify();
        get_hospital_list_handler();
        setName('');
        setAddress('');
        setGstno('');
        setContact('');
        setEmail('');
        setCommission('');

      }).catch(error => {
        setLoading(false);
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
  }

  const shareholder_image_handler = (files) => {
    console.log(files);
    // if (files.size.split(' ')[0] < 500) {

    let imgtype = (files.type).split('/')[1];

    // if (imgtype == 'png' || imgtype == 'jpg' || imgtype == 'jpeg' || imgtype == 'bmp') {
    setKycImages(files.base64);
    setKycExtension(imgtype);
    //   setF_valid(1);
    // } else {
    //   let notify = notification({ message: "Only image file accepted.", type: 'error' });
    //   notify();
    //   setF_valid(2);
    // }
    // } else {
    //   let notify = notification({ message: "Image should be less then 500 kb.", type: 'error' });
    //   notify();
    //   setF_valid(2);
    // }
  }

  const hospital_kyc_add_handler = (e) => {
    e.preventDefault();
    setLoading(true);
    let postData = {
      "hospital_id": hospitals,
      "kyc_name": kycName,
      "file_link": kycImages,
      "file_ext": kycExtension,
    }
    // console.log(postData);

    axios({
      method: 'post',
      url: mainurl + 'Admin/hospital_kyc_add',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        setLoading(false);
        setHospitalModal(false);
        let notify = notification({ message: "KYC Added Successfully", type: 'success' });
        notify();
        get_hospital_list_handler();
        setKycName('');
        setKycImages('');
        setHospitals('');

      }).catch(error => {
        setLoading(false);
        console.log(error);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
  }

  const delete_handler = (row) => {
    confirmAlert({
      title: 'Please Confirm',
      message: 'Are you sure you want to delete ' + row.name + ' ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let postData = { "hospital_id": row.hospital_id, "status": 0 };

            axios({
              method: 'post',
              url: mainurl + 'Admin/hospital_status_update',
              data: postData,
              headers: { 'Content-Type': 'application/JSON', }
            })
              .then(function (response) {

                let notify = notification({ message: "Hospital deleted successfully", type: 'success' });
                notify();
                get_hospital_list_handler();

              }).catch(error => {
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
              })

          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const getHospitals = async () => {
    try {
      const response = await axios.get(mainurl + 'Admin/hospital_master_list');
      setHospitalName(response.data);
    } catch (error) {
      console.log("Error fetching hospitals:", error);
    }
  }

  const open_edit_handler = (row) => {
    setHospitalId(row.hospital_id)
    setEdName(row.name);
    setEdAddress(row.address);
    setEdContact(row.phone_no);
    setEdEmail(row.email);
    setEdGstno(row.gst_no);
    setEdCommission(row.commission);
    setHospitalEditModal(true);
  }

  const hospital_edit_handler = (e) => {
    e.preventDefault();
    setLoading(true);

    let postData = {
      // "name": edName,
      "address": edAddress,
      "phone_no": edContact,
      "email": edEmail,
      "gst_no": edGstno,
      "commission": edCommission,
      "status": 1,
      "hospital_id": hospitalId
    };

    axios({
      method: 'post',
      url: mainurl + 'Admin/hospital_master_edit',
      data: postData,
      headers: { 'Content-Type': 'application/JSON', }
    })
      .then(function (response) {

        setLoading(false);
        setHospitalEditModal(false);
        let notify = notification({ message: "Hospital Updated successfully", type: 'success' });
        notify();
        get_hospital_list_handler();

      }).catch(error => {
        setLoading(false);
        let notify = notification({ message: "Failed! Try Again", type: 'error' });
        notify();
      })
  }

  const filter_data_handler = (e) => {
    let filterData = tableData.filter(item => {
      const query = e.target.value.toLowerCase();
      console.log(query);
      return (
        item?.HospitalName?.toLowerCase().indexOf(query) >= 0 ||
        item?.Contact?.toLowerCase().indexOf(query) >= 0 ||
        item?.Address?.toLowerCase().indexOf(query) >= 0 ||
        item?.Email?.toLowerCase().indexOf(query) >= 0 ||
        item?.Gstin?.toLowerCase().indexOf(query) >= 0
      )
    })
    setFilterData(filterData);
  }

  return (
    <div>
      <Header />
      <Page>
        <Row className="mb-2 align-items-center">
          <Col xs={6}>
            <Title pageTitle="Hospital List" />
          </Col>

          <Col xs={6} className="d-flex justify-content-end align-items-center">
            <Button className="text-white me-2" color="primary" onClick={() => setHospitalModal(true)}>
              <FaUnity size={20} /> Add Hospital
            </Button>

            <Button className="text-white me-2" color="primary" onClick={() => setHospitalKycModal(true)}>
              <FaUnity size={20} /> Add KYC
            </Button>

            <InputGroup size="sm" className="me-2" style={{ maxWidth: '400px' }}>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input type="text" placeholder="Search" onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>

        <Card color="primary" outline className='mb-3'>
          <CardBody>
            <Row>
              <Col md={12} className='mb-2'>
                <DataTable
                  className='mb-1'
                  customStyles={custTableStyles}
                  columns={[
                    {
                      name: '#',
                      selector: row => row.sl,
                      width: "50px"
                    },
                    {
                      name: 'Hospital Name',
                      selector: row => row.HospitalName,
                    },
                    {
                      name: 'Address',
                      selector: row => row.Address,
                      wrap: true
                    },
                    {
                      name: 'Contact',
                      selector: row => row.Contact,
                      wrap: true
                    },
                    {
                      name: 'Email',
                      selector: row => row.Email,
                      wrap: true,
                      width: '200px'
                    },
                    {
                      name: 'Gstin',
                      selector: row => row.Gstin,
                      wrap: true
                    },
                    {
                      name: 'Commission',
                      selector: row => row.Commission,
                      wrap: true
                    },
                    {
                      name: 'KYC Status',
                      selector: row => row.Kyc,
                      wrap: true
                    },
                    {
                      name: 'Action',
                      selector: row => row.Action,
                    },
                  ]}
                  data={filterData}
                  highlightOnHover
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  progressPending={loading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={hospitalModal}
          toggle={() => setHospitalModal(!hospitalModal)}
        >
          <ModalHeader toggle={() => setHospitalModal(!hospitalModal)}>
            Add New Hospital
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={hospital_add_handler}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label for="hospital_name"> Hospital Name* </Label>
                  <Input id="name" placeholder="Enter Hospital Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="contact"> Contact No* </Label>
                  <Input id="contact" placeholder="Enter Contact Number" type="number" value={contact} onChange={(e) => setContact(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="email"> Email* </Label>
                  <Input id="email" placeholder="Enter Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="gstno"> GST Number* </Label>
                  <Input id="gstno" placeholder="Enter GST Number" type="text" value={gstno} onChange={(e) => setGstno(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="commission"> Commission* </Label>
                  <Input id="commission" placeholder="Set Commission" type="number" value={commission} onChange={(e) => setCommission(e.target.value)} required />
                </Col>
                <Col xs={12} className='mb-2'>
                  <Label for="address"> Address* </Label>
                  <Input id="address" placeholder="Enter Address" type="textarea" value={address} onChange={(e) => setAddress(e.target.value)} required />
                </Col>
                <Col xs={12} className='mb-2'>
                  <b>Note: </b> (*) Mark field are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={hospitalEditModal}
          toggle={() => setHospitalEditModal(!hospitalEditModal)}
        >
          <ModalHeader toggle={() => setHospitalEditModal(!hospitalEditModal)}>
            Edit Hospital
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={hospital_edit_handler}>
              <Row>
                {/* <Col xs={12} className='mb-2'>
                  <Label for="hospital_name"> Hospital Name* </Label>
                  <Input id="name" placeholder="Hospital Name" type="text" value={edName} onChange={(e) => setEdName(e.target.value)} required />
                </Col> */}
                <Col xs={6} className='mb-2'>
                  <Label for="contact"> Contact No* </Label>
                  <Input id="contact" placeholder="Contact Number" type="number" value={edContact} onChange={(e) => setEdContact(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="email"> Email* </Label>
                  <Input id="email" placeholder="Email" type="email" value={edEmail} onChange={(e) => setEdEmail(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="gstno"> Gst No* </Label>
                  <Input id="gstno" placeholder="Gst No" type="text" value={edGstno} onChange={(e) => setEdGstno(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="commission"> Commission* </Label>
                  <Input id="commission" placeholder="Commission" type="number" value={edCommission} onChange={(e) => setEdCommission(e.target.value)} required />
                </Col>
                <Col xs={6} className='mb-2'>
                  <Label for="address"> Address* </Label>
                  <Input id="address" placeholder="Address" type="textarea" value={edAddress} onChange={(e) => setEdAddress(e.target.value)} required />
                </Col>
                <Col xs={12} className='mb-2'>
                  <b>Note: </b> (*) Mark field are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading} > UPDATE {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          centered
          scrollable
          size="lg"
          isOpen={hospitalKycModal}
          toggle={() => setHospitalKycModal(!hospitalKycModal)}
        >
          <ModalHeader toggle={() => setHospitalKycModal(!hospitalKycModal)}>
            Add Hospital KYC
          </ModalHeader>
          <ModalBody className=''>
            <Form onSubmit={hospital_kyc_add_handler}>
              <Row>
                <Col xs={12} className='mb-2'>
                  <Label>Hospital Name*</Label>
                  <Input
                    id="hospital"
                    name="hospital"
                    type="select"
                    value={hospitals}
                    onChange={(e) => setHospitals(e.target.value)}
                    style={{ fontSize: '13px' }}
                    required
                  >
                    <option value="">--Select Hospital--</option>
                    {hospitalName.map((hosp) => (
                      <option key={hosp.hospital_id} value={hosp.hospital_id}>{hosp.name}</option>
                    ))}
                  </Input>
                </Col>
                <Col xs={12} className='mb-2'>
                  <Label for="kyc_name">KYC Name*</Label>
                  <Input
                    id="kyc_name"
                    placeholder="Enter KYC Name"
                    type="text"
                    value={kycName}
                    onChange={(e) => setKycName(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={12} className='mb-2'>
                  <Label>Upload KYC Images</Label><br />
                  <FileBase64
                    multiple={false}
                    onDone={shareholder_image_handler}
                  />
                  {/* <Input
                    id="kyc_images"
                    type="file"
                    onChange={(e) => setKycImages(e.target.files)}
                  /> */}
                </Col>
                <Col xs={12} className='mb-2'>
                  <b>Note: </b> (*) Mark field are mandatory.
                </Col>
                <Col xs={12} className='mb-2 text-end'>
                  <Button className='text-white mt-2' block color='primary' disabled={loading} > ADD {loading && <Spinner color="secondary" >Loading...</Spinner>} </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Page>
      <Footer />
    </div>
  )
}

export default HospitalList;