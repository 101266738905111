import { Routes, Route } from "react-router-dom";

import WIP from './pages/WIP';
import './style/main.scss';

import Login from "./pages/Login";
import Home from './pages/Home';

import HospitalList from './pages/demo/HospitalList';
import HospitalView from './pages/demo/HospitalView';
import ChannelList from './pages/demo/ChannelList';
import ChannelView from './pages/demo/ChannelView';
import PatientList from './pages/demo/PatientList';
import PatientView from './pages/demo/PatientView';

import HospitalPaymentReport from './pages/demo/HospitalPaymentReport';
import ChannelPaymentReport from './pages/demo/ChannelPaymentReport';
import LiabilityReport from './pages/demo/LiabilityReport';
import AccountReport from './pages/demo/AccountReport';

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="*" element={<WIP />} />
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/HospitalList" element={<HospitalList />} />
        <Route path="/HospitalView/:hospital_id" element={<HospitalView />} />
        <Route path="/ChannelList" element={<ChannelList />} />
        <Route path="/ChannelView/:channel_id" element={<ChannelView />} />
        <Route path="/PatientList" element={<PatientList />} />
        <Route path="/PatientView/:patient_id" element={<PatientView />} />

        <Route path="/HospitalPaymentReport" element={<HospitalPaymentReport />} />
        <Route path="/ChannelPaymentReport" element={<ChannelPaymentReport />} />
        <Route path="/LiabilityReport" element={<LiabilityReport />} />
        <Route path="/AccountReport" element={<AccountReport />} />
      </Routes>
    </div>
  );
}

export default App;