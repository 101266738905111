import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';
import Title from '../../component/layout/Title';

import axios from 'axios';
import { notification } from '../../component/hocs/notification';
import { mainurl } from '../../MainUrl';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import {
  Row, Col, Card, CardHeader, CardBody, Button, Input, InputGroup, InputGroupText
} from 'reactstrap';

import { FaUnity, FaSearch, FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const ChannelPaymentReport = () => {
  const log_data = useSelector((state) => state.logged.loginData);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    get_hospital_payments_handler();
  }, []);

  const get_hospital_payments_handler = () => {
    setLoading(true);
    setTableData('');

    axios.post(mainurl + 'Admin/channel_payment_list')
      .then(function (response) {
        console.log(response.data);
        const list = response.data
          .filter(row => row.channal_amount_status === '0')
          .map((row, index) => {
            let status = row.channal_amount_status === '0' ? 'Unpaid' : 'Paid';

            const formatDate = (date) => {
              if (!date || date === "0000-00-00") {
                return "-";
              }
              const parsedDate = new Date(date);
              return `${parsedDate.getDate().toString().padStart(2, '0')}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getFullYear()}`;
            };

            return (
              {
                sl: index + 1,
                PaymentDate: formatDate(row.channel_due_date),
                ChannelName: row.name,
                PatientName: row.patient_name,
                HospitalName: row.hospital_name,
                ChannelContact: row.phone_no,
                ChannelAmount: '₹' + row.channel_amount,
                PaymentCycle: row.channel_payment_cy + " Days",
                Status: status,
              }
            )
          })
        setTableData(list);
        setFilterData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const filter_data_handler = (e) => {
    let filterData = tableData.filter(item => {
      const query = e.target.value.toLowerCase();
      console.log(query);
      return (
        item?.ChannelName?.toLowerCase().indexOf(query) >= 0 ||
        item?.PatientName?.toLowerCase().indexOf(query) >= 0 ||
        item?.HospitalName?.toLowerCase().indexOf(query) >= 0 ||
        item?.PaymentDate?.toLowerCase().indexOf(query) >= 0 ||
        item?.ChannelContact?.toLowerCase().indexOf(query) >= 0 ||
        item?.ChannelAmount?.toLowerCase().indexOf(query) >= 0 ||
        item?.PaymentCycle?.toLowerCase().indexOf(query) >= 0
      )
    })
    setFilterData(filterData);
  }

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filterData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Liability Report");
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(data, "Liability_Report.xlsx");
  }

  return (
    <div>
      <Header />
      <Page>
        <Row>
          <Col xs={6} className='mb-2'>
            <Title pageTitle="Liability Report" />
          </Col>
          <Col xs={6} className="d-flex justify-content-end align-items-center mb-2">
            <Button className="text-white me-2" color='success' onClick={exportToExcel}>
              <FaFileExcel /> Export to Excel
            </Button>
            <InputGroup size="sm" className="me-2" style={{ maxWidth: '400px' }}>
              <InputGroupText>
                <FaSearch />
              </InputGroupText>
              <Input type="text" placeholder='Search' onChange={filter_data_handler} />
            </InputGroup>
          </Col>
        </Row>
        {log_data.roleId === 1 && (
          <>
            <Card color="primary" outline className="mb-3">
              <CardBody>
                <Row>
                  <Col md={12} className='mb-2'>
                    <DataTable
                      className='mb-1'
                      customStyles={custTableStyles}
                      columns={[
                        {
                          name: '#',
                          selector: row => row.sl,
                          width: "50px"
                        },
                        {
                          name: 'Payout Due Date',
                          selector: row => row.PaymentDate,
                        },
                        {
                          name: 'Channel Name',
                          selector: row => row.ChannelName,
                        },
                        {
                          name: 'Patient Name',
                          selector: row => row.PatientName,
                          wrap: true
                        },
                        {
                          name: 'Hospital Name',
                          selector: row => row.HospitalName,
                          wrap: true
                        },
                        {
                          name: 'Payment Cycle',
                          selector: row => row.PaymentCycle,
                          wrap: true
                        },
                        {
                          name: 'Unpaid Referral Amount',
                          selector: row => row.ChannelAmount,
                          wrap: true
                        },
                        // {
                        //   name: 'Status',
                        //   selector: row => row.Status,
                        //   wrap: true,
                        //   cell: row => (
                        //     <span style={{ color: row.Status === 'Unpaid' ? 'red' : 'green' }}>
                        //       {row.Status}
                        //     </span>
                        //   )
                        // },
                      ]}
                      data={tableData}
                      highlightOnHover
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      progressPending={loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>)}
      </Page>
      <Footer />
    </div>
  )
}

export default ChannelPaymentReport;