import React from "react";

const Page = ({ children, ...props }) => {
  return (
    <div
      className="pageContainer p-3"
      style={{ minHeight: "500px", marginTop: "70px" }}
    >
      {children}
    </div>
  );
};
export default Page;
