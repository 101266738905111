import React from "react";

const Title = ({ pageTitle, ...props }) => {
  return (
    <div className="fs-bold fs-18" id="pageTitle">
      {pageTitle}
    </div>
  );
};
export default Title;
